import { render, staticRenderFns } from "./form.vue?vue&type=template&id=2fcd4020&scoped=true&"
import script from "./form.vue?vue&type=script&lang=js&"
export * from "./form.vue?vue&type=script&lang=js&"
import style0 from "./form.vue?vue&type=style&index=0&id=2fcd4020&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fcd4020",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2fcd4020')) {
      api.createRecord('2fcd4020', component.options)
    } else {
      api.reload('2fcd4020', component.options)
    }
    module.hot.accept("./form.vue?vue&type=template&id=2fcd4020&scoped=true&", function () {
      api.rerender('2fcd4020', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/mall/views/integral_mall_management/integral_goods_off_shelves/form/form.vue"
export default component.exports